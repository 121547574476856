// store/modules/facturama.js
import axios from 'axios';
import { api } from '../../api/axios-base';
const FACTURAMA_URL = process.env.VUE_APP_ENVIRONMENT === 'prod'
  ? 'https://api.facturama.mx/catalogs'
  : 'https://apisandbox.facturama.mx/api/catalogs';

const state = {
  cache: {},
  pendingRequests: {},
};

const mutations = {
  SET_CACHE(state, { key, data, expiry }) {
    state.cache = { ...state.cache, [key]: { data, expiry } };
  },
  REMOVE_CACHE_ENTRY(state, key) {
    const newCache = { ...state.cache };
    delete newCache[key];
    state.cache = newCache;
  },
  SET_PENDING_REQUEST(state, { key, promise }) {
    state.pendingRequests = { ...state.pendingRequests, [key]: promise };
  },
  REMOVE_PENDING_REQUEST(state, key) {
    const newRequests = { ...state.pendingRequests };
    delete newRequests[key];
    state.pendingRequests = newRequests;
  },
};

const actions = {
  async fetchDefaultTaxInfo({ dispatch }, { user, password, institutionId, ttl = 7 * 24 * 60 * 60 * 1000 }) {
    const cacheKey = `defaultTaxInfo_${institutionId}`;
    const now = Date.now();

    const localStorageData = JSON.parse(localStorage.getItem(cacheKey));

    if (localStorageData && localStorageData.expiry > now) return localStorageData.data;

    try {
      const usageInvoice = await dispatch('fetchUsageInvoiceDefault');

      const regimentTypes = await dispatch('fetchFacturama', { user, password, url: '/FiscalRegimens' });

      const genericTaxInfo = {
        tax_id: 'XAXX010101000',
        nombre_o_razon_social: 'PÚBLICO EN GENERAL',
        regimen_fiscal: regimentTypes.find(item => item.Value === '616'),
        uso_factura: usageInvoice,
      };

      const cachedData = {
        key: cacheKey,
        data: genericTaxInfo,
        expiry: now + ttl,
      };

      localStorage.setItem(cacheKey, JSON.stringify(cachedData));

      return genericTaxInfo;
    } catch (error) {
      console.error('Error fetching default tax info:', error);
      throw error;
    }
  },

  async fetchUsageInvoiceDefault() {
    try {
      const { data: usageInvoice } = await api.get('/app-personas/filters/uso-factura?clave=S01&estatus_sistema=true');
      if (!usageInvoice.results.length) {
        const { data: newItem } = await api.post('/app-personas/uso-factura', {
          dato: 'Sin efectos fiscales',
          clave: 'S01',
        });
        return newItem;
      }
      return usageInvoice.results[0];
    } catch (error) {
      console.error('Error fetching usage invoice default:', error);
      throw error;
    }
  },


  async fetchDataFacturama({ dispatch }, { user, password }) {
    try {
      const [
        regimentTypes,
        currency,
        paymentForms,
        cfdiTypes,
        paymentMethods,
      ] = await Promise.all([
        dispatch('fetchFacturama', { user, password, url: '/FiscalRegimens' }),
        dispatch('fetchFacturama', { user, password, url: '/Currencies?keyword=Peso Mexicano' }),
        dispatch('fetchFacturama', { user, password, url: '/PaymentForms' }),
        dispatch('fetchFacturama', { user, password, url: '/CfdiTypes' }),
        dispatch('fetchFacturama', { user, password, url: '/PaymentMethods' }),
      ]);

      return {
        regimentTypes,
        currency,
        paymentForms,
        cfdiTypes,
        paymentMethods,
      };
    } catch (error) {
      console.error('Error fetching form options:', error);
      throw error;
    }
  },

  async fetchFacturama({ state, commit }, { user, password, url, ttl = 7 * 24 * 60 * 60 * 1000 }) {
    const cacheKey = `${url}_${user}`;
    const now = Date.now();

    const localStorageData = JSON.parse(localStorage.getItem(cacheKey));

    if (localStorageData && localStorageData.expiry > now) return localStorageData.data;

    if (state.cache[cacheKey] && state.cache[cacheKey].expiry > now) return state.cache[cacheKey].data;

    if (state.pendingRequests[cacheKey]) {
      return state.pendingRequests[cacheKey];
    }

    try {
      const apiFacturama = axios.create({
        baseURL: FACTURAMA_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + Buffer.from(`${user}:${password}`).toString('base64'),
        },
      });

      const fetchPromise = apiFacturama.get(url);
      commit('SET_PENDING_REQUEST', { key: cacheKey, promise: fetchPromise });

      const res = await fetchPromise;

      const cachedData = {
        key: cacheKey,
        data: res.data,
        expiry: now + ttl,
      };

      commit('SET_CACHE', cachedData);
      localStorage.setItem(cacheKey, JSON.stringify(cachedData));
      commit('REMOVE_PENDING_REQUEST', cacheKey);

      return res.data;
    } catch (error) {
      console.error('Error al obtener los datos de Facturama:', error);
      commit('REMOVE_PENDING_REQUEST', cacheKey);
      throw error;
    }
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
