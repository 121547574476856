import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import pos from './modules/pos';
import permissions from './modules/permissions';
import { getItem, commonPost } from '@/api/api-methods';
import {
  fetchThemes,
  getLocalTheme,
  removeLocalTheme,
  setLocalTheme,
  setVuetifyColors,
} from '../modules/shared/helpers/themeOptions';

import { UserTypes } from '../modules/shared/helpers/permissionContants';
import { checkUserTypeMatch } from '../modules/shared/helpers/permissionsUtils';
import facturama from './modules/facturama';

Vue.use(Vuex);

const getTheme = () => {
  const localTheme = getLocalTheme();
  if (localTheme) {
    setVuetifyColors(localTheme);
  }
  return localTheme;
};

export default new Vuex.Store({
  state: {
    isLogin: localStorage.getItem('isLogin') || false,
    isPOS: false,
    userData: JSON.parse(localStorage.getItem('userData')) || null,
    apiTokens: JSON.parse(localStorage.getItem('apiTokens')) || null,
    userProfile: parseInt(localStorage.getItem('userProfile')) || null,
    institutionId: parseInt(localStorage.getItem('institutionId')) || null,
    warehouse: JSON.parse(localStorage.getItem('warehouse')) || null,
    showAlert: false,
    alertType: 'error',
    alertText: 'Espere, por favor',
    loadingAlert: false,
    search: null,
    loginError: false,
    loadingLogin: false,
    theme: getTheme(),
    hasDebts: JSON.parse(localStorage.getItem('kpTutorHasDebts')) || false,
    selectedId: undefined,
    currentPage: 1,
    currentInstitution:
      JSON.parse(localStorage.getItem('kpCurrentInstitution')) || null,
    mixpanel: null,
    institutions: JSON.parse(localStorage.getItem('kpInstitutions')) || null,
  },
  mutations: {
    setIsLogin: (state, payload) => (state.isLogin = payload),
    setIsPOS: (state, payload) => (state.isPOS = payload),
    setUserData: (state, payload) => (state.userData = payload),
    setApiTokens: (state, payload) => (state.apiTokens = payload),
    setUserProfile: (state, payload) => (state.userProfile = payload),
    setInstitutionId: (state, payload) => (state.institutionId = payload),
    setWarehouse: (state, payload) => (state.warehouse = payload),
    setShowAlert: (state, payload) => (state.showAlert = payload),
    setAlertType: (state, payload) => (state.alertType = payload),
    setAlertText: (state, payload) => (state.alertText = payload),
    setLoadingAlert: (state, payload) => (state.loadingAlert = payload),
    setSearch: (state, payload) => (state.search = payload),
    setLoginError: (state, payload) => (state.loginError = payload),
    setLoadingLogin: (state, payload) => (state.loadingLogin = payload),
    setTheme: (state, payload) => (state.theme = payload),
    setHasDebts: (state, payload) => (state.hasDebts = payload),
    setSelectedId(state, payload) {
      state.selectedId = payload;
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload;
    },
    setCurrentInstitution: (state, payload) =>
      (state.currentInstitution = payload),
    setMixpanel: (state, payload) => (state.mixpanel = payload),
    setInstitutions: (state, payload) => (state.institutions = payload),
  },
  actions: {
    async login({ commit, state, dispatch, getters }, userCredentials) {
      try {
        //const response = await commonAPI.post('/land/api/token/', userCredentials)
        commit('setLoadingLogin', true);
        const responseData = await commonPost(
          '/app-land/api/token/',
          userCredentials
        );

        // console.log('Resultado responseData login: ', responseData);
        if (responseData && responseData.e) {
          commit('setLoginError', true);
          return;
        }

        if (responseData) {
          const { access, refresh } = responseData;
          commit('setLoginError', false);
          commit('setApiTokens', { access, refresh });
          localStorage.setItem(
            'apiTokens',
            JSON.stringify({ access, refresh })
          );

          // commit('setUserData', responseData);
          // localStorage.setItem('userData', JSON.stringify(responseData));

          await dispatch('setUserData', responseData);

          await dispatch('setUserProfile');
          await dispatch('setWarehouse');

          state.mixpanel.identify(state.userData.username);
          state.mixpanel.people.set({
            $name: getters.nameUserLoggedIn,
            $email: state.userData.email,
            user_profile: getters.userProfile,
            institution_name: getters.currentInstitutionName,
            profiles: JSON.stringify(state.userData.perfiles),
          });
          if (
            getters.isSuperAdmin ||
            getters.isAdminEmployee ||
            getters.isAccountingEmployee ||
            getters.isAccountingAssistantEmployee
          ) {
            router.replace({ name: 'Dashboard' });
          } else if (
            getters.isSchoolControlEmployee ||
            getters.isSuperSchoolControlEmployee ||
            getters.isCoordinatorEmployee ||
            getters.isAdministrativeEmployee ||
            getters.isAdministrativeAssistantEmployee ||
            getters.isTeacherEmploye ||
            getters.isCollectionEmployee
          ) {
            router.replace({ name: 'Dashboard Docentes' });
          } else if (getters.isTutor) {
            router.replace({ name: 'Dashboard Tutores' });
          } else {
            commit('setLoginError', true);
            dispatch('logout');
            return;
          }

          await dispatch('setInstitutionTheme');
        }
      } catch (error) {
        console.error('Error al obtener token', error);
        dispatch('logout');
      } finally {
        commit('setLoadingLogin', false);
      }
    },
    /// AUTOR: Tania Marin
    /// RESEÑANTE: Raúl Altamirano Lozano
    /// FECHA: 29/07/2022
    /// COMENTARIOS:
    /// - Verificar el refresh token, cada que el usuario ingresa después de un tiempo.
    async refreshToken({ commit, state, dispatch }) {
      try {
        const refreshToken = state.apiTokens.refresh;
        const responseData = await commonPost('/app-land/api/token/refresh/', {
          refresh: refreshToken,
        });
        //const responseData = await response.data
        const tokens = { access: responseData.access, refresh: refreshToken };
        commit('setApiTokens', tokens);
        localStorage.setItem('apiTokens', JSON.stringify(tokens));
      } catch (error) {
        console.error('Error al obtener refreshToken: ', error);
        dispatch('logout');
        router.replace({ name: 'Login' });
      }
    },
    async setUserData({ commit, dispatch, state }, userData) {
      try {
        const responseData = await getItem(
          `/app-personas/helpers/consultar-informacion-usuario/${userData.id}`
        );
        // console.log('Respuesta informacion usuario', responseData);
        if (responseData.e) {
          commit('setLoginError', true);
          return;
        }

        const fullData = { ...userData, ...responseData };

        commit('setUserData', fullData);
        localStorage.setItem('userData', JSON.stringify(fullData));

        dispatch(
          'permissions/setCurrentPermissionsGroup',
          state.userData.grupo_permisos
        );
      } catch (error) {
        console.error(
          'Error al intentar establecer informacion de usuario',
          error
        );
        commit('setLoginError', true);
        return;
      }
    },
    async setWarehouse({ commit, state }) {
      try {
        const institutionId = state.institutionId;
        const responseData = await getItem(
          `/app-inventarios/filters/almacen?institucion_educativa=${institutionId}`
        );
        const allWarehouse = responseData.results;
        const defaultWarehouse = allWarehouse.find(
          (warehouse) => warehouse.almacen_predeterminado === true
        );
        // console.log('Warehouse', defaultWarehouse);
        if (defaultWarehouse) {
          commit('setWarehouse', defaultWarehouse);
          localStorage.setItem('warehouse', JSON.stringify(defaultWarehouse));
        } else {
          commit('setWarehouse', null);
          localStorage.removeItem('warehouse');
        }
      } catch (error) {
        console.error('Error al intentar establecer almacen', error);
      }
    },
    logout({ commit, dispatch }) {
      commit('setIsLogin', true);
      commit('setApiTokens', null);
      commit('setUserData', null);
      commit('setUserProfile', null);
      commit('setInstitutionId', null);
      commit('setWarehouse', null);
      commit('setSearch', null);
      commit('setTheme', null);
      commit('setCurrentInstitution', null);
      commit('setHasDebts', false);
      commit('setInstitutions', null);

      localStorage.removeItem('isLogin');
      localStorage.removeItem('apiTokens');
      localStorage.removeItem('userData');
      localStorage.removeItem('userProfile');
      localStorage.removeItem('institutionId');
      localStorage.removeItem('warehouse');
      localStorage.removeItem('kpTutorHasDebts');
      localStorage.removeItem('kpCurrentInstitution');
      localStorage.removeItem('kpInstitutions');
      dispatch('setTheme', null);
      dispatch('permissions/setCurrentPermissionsGroup', null);
    },
    async setUserProfile({ commit, state, dispatch }) {
      try {
        let userProfile = null;
        let institutionId = null;
        const currentUserType = state.userData.tipo_usuario;
        if (currentUserType.tipo_usuario !== '') {
          let currentInstitution = state.userData.institucion_educativa.length
            ? state.userData.institucion_educativa[0]
            : null;

          institutionId = currentInstitution ? currentInstitution.id : null;

          if (
            state.userData.superusuario ||
            checkUserTypeMatch(currentUserType, 'Super administrador') ||
            checkUserTypeMatch(currentUserType, 'super_administrador')
          ) {
            userProfile = UserTypes.SUPERADMIN;

            // El super administrador va a poder ver el
            // listado de todas las escuelas disponibles
            const { results, e } = await getItem(
              '/app-personas/institucion-educativa'
            );
            if (e) {
              console.log(
                'No se pudieron obtener las instituciones educativas',
                e
              );
            } else {
              commit('setInstitutions', results);
              localStorage.setItem('kpInstitutions', JSON.stringify(results));

              // En caso de que el usuario no se encuentre asociado
              // a ninguna escuela se va establecer como institucion
              // predeterminada la primera opción de la lista de
              // institucines
              if (!currentInstitution) {
                currentInstitution = results[0];
                institutionId = currentInstitution.id;
              }
            }
          } else {
            if (
              checkUserTypeMatch(currentUserType, 'super control escolar') ||
              checkUserTypeMatch(currentUserType, 'super_control _escolar') ||
              checkUserTypeMatch(currentUserType, 'control escolar') ||
              checkUserTypeMatch(currentUserType, 'control_escolar')
            ) {
              // El usuario super control escolar va a poder ver el
              // listado de todas las escuelas disponibles
              userProfile = UserTypes.SCHOOLCONTROL_EMPLOYEE;
              const { results, e } = await getItem(
                '/app-personas/institucion-educativa'
              );
              if (e) {
                console.log(
                  'No se pudieron obtener las instituciones educativas',
                  e
                );
              } else {
                commit('setInstitutions', results);
                localStorage.setItem('kpInstitutions', JSON.stringify(results));
              }
            } else if (
              checkUserTypeMatch(currentUserType, 'administrador keystone') ||
              checkUserTypeMatch(currentUserType, 'administrador_keystone')
            ) {
              userProfile = UserTypes.KEYSTONE_ADMIN_EMPLOYEE;
            } else if (
              checkUserTypeMatch(currentUserType, 'coordinador operativo') ||
              checkUserTypeMatch(currentUserType, 'coordinador_operativo')
            ) {
              userProfile = UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE;
            } else if (
              checkUserTypeMatch(currentUserType, 'control escolar primaria') ||
              checkUserTypeMatch(currentUserType, 'control_escolar_primaria') ||
              checkUserTypeMatch(currentUserType, 'coordinador primaria') ||
              checkUserTypeMatch(currentUserType, 'coordinador_primaria') ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_academico_primaria'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador academico primaria'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_académico_primaria'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador académico primaria'
              )
            ) {
              userProfile = UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE;
            } else if (
              checkUserTypeMatch(
                currentUserType,
                'coordinador secundaria y bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_secundaria_bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_academico_secundaria_bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador academico secundaria y bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_académico_secundaria_bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador académico secundaria y bachiller'
              )
            ) {
              userProfile = UserTypes.SECUNDARIA_BACHILLER_COORDINATOR_EMPLOYEE;
            } else if (
              checkUserTypeMatch(
                currentUserType,
                'control escolar secundaria'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'control_escolar_secundaria'
              ) ||
              checkUserTypeMatch(currentUserType, 'coordinador secundaria') ||
              checkUserTypeMatch(currentUserType, 'coordinador_secundaria') ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_academico_secundaria'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador academico secundaria'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_académico_secundaria'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador académico secundaria'
              )
            ) {
              userProfile = UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE;
            } else if (
              checkUserTypeMatch(
                currentUserType,
                'control escolar bachillerato'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'control_escolar_bachillerato'
              ) ||
              checkUserTypeMatch(currentUserType, 'coordinador bachiller') ||
              checkUserTypeMatch(currentUserType, 'coordinador_bachiller') ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_academico_bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador academico bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_académico_bachiller'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador académico bachiller'
              )
            ) {
              userProfile = UserTypes.BACHILLER_COORDINATOR_EMPLOYEE;
            } else if (
              checkUserTypeMatch(currentUserType, 'control escolar kinder') ||
              checkUserTypeMatch(currentUserType, 'control_escolar_kinder') ||
              checkUserTypeMatch(currentUserType, 'coordinador kinder') ||
              checkUserTypeMatch(currentUserType, 'coordinador_kinder') ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_academico_kinder'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador academico kinder'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador_académico_kinder'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'coordinador académico kinder'
              )
            ) {
              userProfile = UserTypes.KINDER_COORDINATOR_EMPLOYEE;
            } else if (
              checkUserTypeMatch(currentUserType, 'empleado administrativo') ||
              checkUserTypeMatch(currentUserType, 'empleado_administrativo') ||
              checkUserTypeMatch(currentUserType, 'administrativo')
            ) {
              userProfile = UserTypes.ADMINISTRATIVE_EMPLOYEE;
            } else if (
              checkUserTypeMatch(
                currentUserType,
                'empleado administrativo auxiliar'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'empleado_administrativo_auxiliar'
              )
            ) {
              userProfile = UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE;
            } else if (
              checkUserTypeMatch(currentUserType, 'empleado contabilidad') ||
              checkUserTypeMatch(currentUserType, 'empleado_contabilidad') ||
              checkUserTypeMatch(currentUserType, 'contabilidad')
            ) {
              userProfile = UserTypes.ACCOUNTING_EMPLOYEE;
            } else if (
              checkUserTypeMatch(
                currentUserType,
                'empleado contabilidad auxiliar'
              ) ||
              checkUserTypeMatch(
                currentUserType,
                'empleado_contabilidad_auxiliar'
              ) ||
              checkUserTypeMatch(currentUserType, 'auxiliar contable') ||
              checkUserTypeMatch(currentUserType, 'auxiliar_contable')
            ) {
              userProfile = UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE;
            } else if (checkUserTypeMatch(currentUserType, 'cobranza')) {
              userProfile = UserTypes.COLLECTION_EMPLOYEE;
            } else if (
              checkUserTypeMatch(currentUserType, 'empleado docente') ||
              checkUserTypeMatch(currentUserType, 'empleado_docente') ||
              checkUserTypeMatch(currentUserType, 'docente')
            ) {
              userProfile = UserTypes.TEACHER_EMPLOYEE;
            } else if (checkUserTypeMatch(currentUserType, 'tutor')) {
              userProfile = UserTypes.TUTOR;
            } else if (checkUserTypeMatch(currentUserType, 'alumno')) {
              userProfile = UserTypes.STUDENT;
            } else if (checkUserTypeMatch(currentUserType, 'proveedor')) {
              userProfile = UserTypes.SUPPLIER;
            }
          }

          // console.log('userProfile selected: ', userProfile);
          // console.log('institutionId selected: ', institutionId);
          // console.log('currentInstitution', currentInstitution);

          commit('setUserProfile', userProfile);
          localStorage.setItem('userProfile', userProfile);

          commit('setInstitutionId', institutionId);
          localStorage.setItem('institutionId', institutionId);

          commit('setCurrentInstitution', currentInstitution);
          localStorage.setItem(
            'kpCurrentInstitution',
            JSON.stringify(currentInstitution)
          );
        } else {
          await dispatch('setUserProfileFallback');
        }
      } catch (error) {
        console.error('Error al determinar perfil del usuario: ', error);
      }
    },
    async setUserProfileFallback({ commit, state }) {
      try {
        let userProfile = null;
        let institutionId = null;
        const profiles = state.userData.perfiles;
        if (state.userData.superusuario) {
          userProfile = UserTypes.SUPERADMIN;
          // TODO: Ver que hacer cuando ocurre que un useradmin no
          // tiene ningun perfil
          institutionId = 1; // profiles[0].proveedor[0].institucion_educativa_id;
        } else {
          for (const p of profiles) {
            // console.log('profile: ', p);
            if (p.proveedor !== null && p.proveedor !== undefined) {
              // console.log('Es Proveedor');
              userProfile = UserTypes.SUPPLIER;
              institutionId = p.proveedor[0].institucion_educativa_id;
            } else if (p.alumno !== null && p.alumno !== undefined) {
              // console.log('Es alumno');
              userProfile = UserTypes.STUDENT;
              institutionId = p.alumno[0].institucion_educativa_id;
            } else if (p.tutor !== null && p.tutor !== undefined) {
              // console.log('Es tutor');
              userProfile = UserTypes.TUTOR;
              institutionId = p.tutor[0].institucion_educativa_id;
            }
            if (p.empleado !== null && p.empleado !== undefined) {
              // console.log('Es empleado: ', p.empleado);
              const employeeTypeId = p.empleado[0].tipo_empleado_id;
              institutionId = p.empleado[0].institucion_educativa_id;

              //const responseData = await getItem(`/personas/api/tipo-empleado-by-ie/${employeeTypeId}/?id_institucion=${institutionId}`)
              const responseData = await getItem(
                `/app-personas/tipo-empleado/${employeeTypeId}`
              );
              //const responseData = await response.data
              const employeeType = responseData.dato;
              // console.log('Tipo empleado: ', employeeType);
              switch (employeeType) {
                case 'Administrativo':
                  userProfile = UserTypes.ADMINISTRATIVE_EMPLOYEE;
                  break;
                case 'Control escolar':
                  userProfile = UserTypes.SCHOOLCONTROL_EMPLOYEE;
                  break;
                case 'Contabilidad':
                  userProfile = UserTypes.ACCOUNTING_EMPLOYEE;
                  break;
                default:
                  userProfile = UserTypes.TEACHER_EMPLOYEE;
              }
            }
          }
        }

        const resInstitution = await getItem(
          `/app-personas/institucion-educativa/${institutionId}`
        );
        // console.log('Institucion fallback: ', resInstitution);
        // console.log('userProfile selected fallback: ', userProfile);
        // console.log('institutionId selected fallback : ', institutionId);

        commit('setUserProfile', userProfile);
        localStorage.setItem('userProfile', userProfile);

        commit('setInstitutionId', institutionId);
        localStorage.setItem('institutionId', institutionId);

        commit('setCurrentInstitution', resInstitution);
        localStorage.setItem(
          'kpCurrentInstitution',
          JSON.stringify(resInstitution)
        );
      } catch (error) {
        console.error('Error al determinar perfil del usuario: ', error);
      }
    },
    showAlert({ commit }, { alertText, alertType, loadingAlert = true }) {
      commit('setAlertType', alertType);
      commit('setAlertText', alertText);
      commit('setLoadingAlert', loadingAlert);
      commit('setShowAlert', true);
    },
    stopLoadingAlert({ commit }) {
      commit('setLoadingAlert', false);
    },
    hideAlert({ commit }) {
      commit('setShowAlert', false);
    },
    clearSearch({ commit }) {
      commit('setSearch', null);
    },
    setTheme({ commit }, theme) {
      // console.log('setTheme tema', theme);
      commit('setTheme', theme);
      if (theme) {
        if (theme.is_activo) {
          // console.log('Se debe establecer tema. ');
          setLocalTheme(theme);
          setVuetifyColors(theme);
        }
      } else {
        // console.log('No se va a establecer');
        removeLocalTheme();
        setVuetifyColors({
          color_principal: '#0047BA',
          color_secundario: '#A45C81',
          color_terciario: '#FFB600',
        });
      }
    },
    async setInstitutionTheme({ dispatch, state }) {
      try {
        const institutionId = state.institutionId;
        const {
          ok: okTheme,
          data: dataTheme,
          // message: messageTheme,
        } = await fetchThemes({ institutionId: institutionId, isActive: true });
        // console.log('setInstitutionTheme themas: ', dataTheme);
        if (okTheme && dataTheme.length) {
          dispatch('setTheme', dataTheme[0]);
        } else {
          // console.log('Error al obtener tema. ' + messageTheme);
          dispatch('setTheme', null);
        }
      } catch (error) {
        console.error('Error al intentar establecer tema. ', error);
      }
    },
    setIfTutorHasDebts(
      { state, commit },
      { overdueMount = 0.0, currentMount = 0.0 }
    ) {
      const totalDebt = overdueMount + currentMount;
      if (totalDebt > 0) {
        commit('setHasDebts', true);
      } else {
        commit('setHasDebts', false);
      }
      localStorage.setItem('kpTutorHasDebts', JSON.stringify(state.hasDebts));
    },
    setCurrentInstitution({ commit }, institution) {
      commit('setCurrentInstitution', institution);
      localStorage.setItem('kpCurrentInstitution', JSON.stringify(institution));

      commit('setInstitutionId', institution.id);
      localStorage.setItem('institutionId', institution.id);
    },
  },
  selectedId: (state) => {
    return state.selectedId ? state.selectedId : null;
  },
  getters: {
    isLoggedIn: (state) => (state.userData !== null ? true : false),
    nameUserLoggedIn: (state) => {
      const userData = state.userData;
      let fullName = '';
      if (userData != null) {
        const { nombre, apellido } = userData;
        fullName = `${nombre} ${apellido}`;
      }
      return fullName;
    },
    userId: (state) => {
      return state.userData ? state.userData.id : null;
    },
    employeeId: (state) => {
      return state.userData &&
        state.userData.perfiles &&
        state.userData.perfiles[3] &&
        state.userData.perfiles[3].empleado
        ? state.userData.perfiles[3].empleado[0].id
        : null;
    },
    tutorId: (state, getters) => {
      return getters.isTutor && state.userData && state.userData.perfiles
        ? state.userData.perfiles[2].tutor[0].id
        : null;
    },
    isSuperAdmin: (state) => state.userProfile === UserTypes.SUPERADMIN,
    isSuperSchoolControlEmployee: (state) =>
      state.userProfile === UserTypes.SCHOOLCONTROL_EMPLOYEE,
    isOperationalCoordinatorEmployee: (state) =>
      state.userProfile === UserTypes.OPERATIONAL_COORDINATOR_EMPLOYEE,
    isSecundariaBachillerCoordinatorEmployee: (state) =>
      state.userProfile === UserTypes.SECUNDARIA_BACHILLER_COORDINATOR_EMPLOYEE,
    isPrimariaCoordinatorEmployee: (state) =>
      state.userProfile === UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
    isSecundariaCoordinatorEmployee: (state) =>
      state.userProfile === UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
    isBachillerCoordinatorEmployee: (state) =>
      state.userProfile === UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
    isKinderCoordinatorEmployee: (state) =>
      state.userProfile === UserTypes.KINDER_COORDINATOR_EMPLOYEE,
    isPrimariaSchoolControlEmployee: (state) =>
      state.userProfile === UserTypes.PRIMARIA_COORDINATOR_EMPLOYEE,
    isSecundariaSchoolControlEmployee: (state) =>
      state.userProfile === UserTypes.SECUNDARIA_COORDINATOR_EMPLOYEE,
    isBachillerSchoolControlEmployee: (state) =>
      state.userProfile === UserTypes.BACHILLER_COORDINATOR_EMPLOYEE,
    isCoordinatorEmployee: (state, getters) =>
      getters.isOperationalCoordinatorEmployee ||
      getters.isSecundariaBachillerCoordinatorEmployee ||
      getters.isPrimariaCoordinatorEmployee ||
      getters.isSecundariaCoordinatorEmployee ||
      getters.isBachillerCoordinatorEmployee ||
      getters.isKinderCoordinatorEmployee,
    isAdministrativeEmployee: (state) =>
      state.userProfile === UserTypes.ADMINISTRATIVE_EMPLOYEE,
    isAdministrativeAssistantEmployee: (state) =>
      state.userProfile === UserTypes.ADMINISTRATIVE_ASSISTANT_EMPLOYEE,
    isTeacherEmploye: (state) =>
      state.userProfile === UserTypes.TEACHER_EMPLOYEE,
    isAdminEmployee: (state) =>
      state.userProfile === UserTypes.KEYSTONE_ADMIN_EMPLOYEE,
    isBasicEmployee: (state) =>
      state.userProfile === UserTypes.TEACHER_EMPLOYEE,
    isTutor: (state) => state.userProfile === UserTypes.TUTOR,
    isSchoolControlEmployee: (state) =>
      state.userProfile === UserTypes.SCHOOLCONTROL_EMPLOYEE,
    isAccountingEmployee: (state) =>
      state.userProfile === UserTypes.ACCOUNTING_EMPLOYEE,
    isAccountingAssistantEmployee: (state) =>
      state.userProfile === UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE,
    isCollectionEmployee: (state) =>
      state.userProfile === UserTypes.COLLECTION_EMPLOYEE,
    tutorHasDebts: (state, getters) =>
      getters.isTutor ? state.hasDebts : false,
    hasAdminPermission: (state, getters) =>
      getters.isSuperAdmin || getters.isAdminEmployee,
    currentInstitutionName: (state) =>
      state.currentInstitution
        ? state.currentInstitution.nombre_comercial
        : 'Institución no asignada',
    currentSchoolLevel: (state, getters) => {
      let level = 'Todos';
      if (
        getters.isPrimariaCoordinatorEmployee ||
        getters.isPrimariaSchoolControlEmployee
      ) {
        level = 'Primaria';
      } else if (getters.isSecundariaBachillerCoordinatorEmployee) {
        level = 'Secundaria';
      } else if (
        getters.isSecundariaCoordinatorEmployee ||
        getters.isSecundariaSchoolControlEmployee
      ) {
        level = 'Secundaria';
      } else if (
        getters.isBachillerCoordinatorEmployee ||
        getters.isBachillerSchoolControlEmployee
      ) {
        level = 'Bachillerato';
      } else if (getters.isKinderCoordinatorEmployee) {
        level = 'Kinder';
      }
      return level;
    },
    allInstitutions: (state) => state.institutions,
    currentSchoolYear: (state, getters) =>
      getters.isLoggedIn
        ? state.userData.ciclo_escolar_actual.length
          ? state.userData.ciclo_escolar_actual[0]
          : null
        : null,
  },
  modules: {
    pos,
    permissions,
    facturama
  },
});
